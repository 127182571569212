<template>
  <div>
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center subtitle-4 black--text">
          المهنة
        </h1>
        <v-row class="mt-5">
          <v-col
            md="3"
            cols="12"
          >
            <v-btn
              tile
              color="primary"
              @click="addDailog.open = true"
            >
              اضافة  مهنة
              <v-icon
                right
                style="font-size: 15px"
              >
                fa-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            md="4"
            cols="12"
          >
            <div class="d-flex flex-row">
              <v-text-field
                v-model="searchingVal"
                label="البحث"
                outlined
                dense
                @keypress.enter="table.search = $event.target.value"
              ></v-text-field>
              <button
                class="search-btn"
                @click="table.search = searchingVal"
              >
                <v-icon style="font-size: 18px; color: white">
                  fa-search
                </v-icon>
              </button>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="table.loading"
              loading-text="جاري التحميل ... الرجاء الانتظار"
              :headers="table.headers"
              :items="table.teacherData"
              :search="table.search"
              :items-per-page="10"
              item-key="customer_jop_id"
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,

              }"
            >
              <template
                slot="item.customer_jop_id"
                slot-scope="props"
              >
                {{ props.index + 1 }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      class="ml-2"
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="edit(item)"
                    >
                      fa-edit
                    </v-icon>
                  </template>
                  <span>تعديل</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#FF5252"
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="deleteItem(item)"
                    >
                      fa-trash
                    </v-icon>
                  </template>
                  <span>حذف</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <!-- addDialog -->
    <v-dialog
      v-model="addDailog.open"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDailog.isFormValid">
              <v-row>
                <!-- customer_jop_name -->
                <v-col cols="12">
                  <v-text-field
                    v-model="addData.customer_jop_name"
                    label="المهنة"
                    outlined
                    :rules="Rules.name"
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="addDailog.open = false"
          >
            الغاء
          </v-btn>
          <v-btn
            color="primary"
            :loading="addDailog.loading"
            :disabled="!addDailog.isFormValid"
            @click="submitAdd"
          >
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- addDialog -->

    <!-- edit dialog -->
    <v-dialog
      v-model="editDailog.open"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="editDailog.isFormValid">
              <v-row>
                <!-- customer_jop_name -->
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.customer_jop_name"
                    label="المهنة"
                    outlined
                    :rules="Rules.name"
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="editDailog.open = false"
          >
            الغاء
          </v-btn>
          <v-btn
            color="primary"
            :loading="editDailog.loading"
            :disabled="!editDailog.isFormValid"
            @click="submitEdit"
          >
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog -->

    <!-- delete dialog -->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="headline justify-center"
        >
          هل انت متأكد من حذف هذه المهنة ؟
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
          >
            الغاء
          </v-btn>
          <v-btn
            color="primary white--text"
            :loading="deleteItemLoading"
            @click="deleteItemConfirm"
          >
            حذف
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dailog -->

    <!--- Dailog for show info to user-->
    <v-dialog
      v-model="dialogData.open"
      max-width="500px"
    >
      <v-toolbar
        :color="dialogData.color"
        dense
      ></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center">
          {{
            dialogData.bodyText
          }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1 justify-start"
            text
            @click="dialogData.open = false"
          >
            تم
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchingVal: '',
      editDailog: {
        loading: false,
        isFormValid: false,
        open: false,
      },

      addDailog: {
        loading: false,
        isFormValid: false,
        open: false,
      },

      deleteItemLoading: false,

      editedItem: {},

      Rules: {
        name: [value => !!value || 'المهنة مطلوبة'],
      },

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      addData: {
        customer_jop_name: null,
      },

      deletedItem: {},

      tableOptions: {},

      table: {
        search: null,
        totalTeacherData: 0,
        teacherData: [],
        loading: true,
        showImageDailog: false,
        imageUrlForShow: null,

        headers: [
          {
            text: '#',
            align: 'start',
            width: '5%',
            sortable: false,
            value: 'customer_jop_id',
          },
          {
            text: 'المهنة',
            sortable: false,
            value: 'customer_jop_name',
          },
          {
            text: 'عدد الزبائن',
            sortable: false,
            value: 'customerNumber',
          },
          {
            text: 'العمليات',
            value: 'actions',
            width: '30%',
            sortable: false,
          },
        ],
      },
    }
  },

  async mounted() {
    this.getDataAxios()
  },
  methods: {
    getDataAxios() {
      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.axios
        .get('/customerJop')
        .then(Response => {
          if (Response.data.status === 401) {
            this.$store.dispatch('submitLogout')
          } else {
            this.table.loading = false
            this.table.teacherData = Response.data.results
          }
        })
        .catch(error => {
          this.table.loading = false
          console.log('error', error)
        })
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.deleteItemLoading = true
      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.axios
        .delete(`customerJop/${this.deletedItem.customer_jop_id}`)
        .then(Response => {
          if (Response.data.error === false) {
            this.deleteItemLoading = false
            this.dialogDelete = false
            this.getDataAxios()
            this.showDialogfunction(Response.data.results, 'primary')
          } else {
            this.showDialogfunction(Response.data.results, '#FF5252')
          }
        })
        .catch(error => {
          this.deleteItemLoading = false
          this.dialogDelete = false
          this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
          console.log('error', error)
        })
    },

    submitAdd() {
      this.addDailog.loading = true

      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

      this.axios
        .post('customerJop', {
          name: this.addData.customer_jop_name,
        })
        .then(Response => {
          this.addDailog.loading = false
          if (Response.data.error === false) {
            this.addDailog.open = false
            this.addData.customer_jop_name = null
            this.showDialogfunction(Response.data.results, 'primary')
            this.getDataAxios()
          } else {
            this.showDialogfunction(Response.data.results, '#FF8A80ed')
          }
        })
        .catch(error => {
          this.addDailog.loading = false
          this.showDialogfunction('حدث خطأ يرجى المحاولة مجددا', '#FF8A80')
          console.log('error', error)
        })
    },

    edit(item) {
      this.editedItem = { ...item }
      this.editDailog.open = true
    },

    submitEdit() {
      this.editDailog.loading = true

      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

      this.axios
        .put('customerJop', { name: this.editedItem.customer_jop_name, id: this.editedItem.customer_jop_id })
        .then(Response => {
          this.editDailog.loading = false
          if (Response.data.error === false) {
            this.showDialogfunction(Response.data.results, 'primary')
            this.getDataAxios()
          } else {
            this.showDialogfunction(Response.data.results, '#FF8A80ed')
          }
        })
        .catch(error => {
          this.addBtnLoading = false
          this.showDialogfunction('حدث خطأ يرجى المحاولة مجددا', '#FF8A80')
          console.log('error', error)
        })
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
}
.search-btn {
  width: 49px;
  height: 40px;
  padding: 10px;
  background: #2196f3;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  cursor: pointer;
  line-height: 100%;
  border-radius: 7px;
}
table.v-table tbody td {
  color: black;
}
</style>
